.scdropdown {
    display: flex;
    position: relative;
    cursor: pointer;
    width: 97%;
    height: 100%;
    padding-left: 4px;
}


.scdropdown-icon {
    margin-left: auto;
}

.scdropdown-icon:hover {
    opacity: 0.8;
}

.rg-date-cell:hover {
    /* background: red; */
}

.reactgrid-content .rg-pane .rg-cell.rg-scdropdown-cell {
    padding: 0;
    overflow: visible;
}

.sc-dropdown-menu {
    top: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
    margin-bottom: 8px;
    margin-top: 2px;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-sizing: border-box;
    z-index: 1000;
}

.sc-dropdown-select {
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 4px;
    padding-top: 4px;
    box-sizing: border-box;
    z-index: 1000;
}

.sc-dropdown-option.focused {
    color: black;
    background-color: #e2ebfe;
}

.sc-dropdown-option {
    z-index: 1000;
    padding: 0.3em 0.5em;
    min-height: 1.5em;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sc-dropdown-option:hover {
    background-color: #111;
    color: #fff;
}

.rg-dropdown-menu {
    top: 0;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.5);
    margin-bottom: 8px;
    margin-top: 2px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    z-index: 1000;
}

.rg-dropdown-select {
    max-height: 300px;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 4px;
    padding-top: 4px;
    box-sizing: border-box;
    z-index: 1000;
}

.rg-dropdown-option.focused {
    color: black;
    background-color: #e2ebfe;
}

.rg-dropdown-option {
    z-index: 1000;
    padding: 0.3em 0.5em;
    min-height: 1.5em;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.rg-dropdown-option:hover {
    background-color: #111;
    color: #fff;
}

.rg-celleditor-input,
.reactgrid-gold .rg-celleditor input,
.reactgrid-red .rg-celleditor input,
.rg-celleditor input {
    border: none;
}